import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { map, isFunction } from "lodash";
import classnames from "classnames";

import { TranslationsContext } from "@skryv/core-react/src/services/translations";
import { useFormContext } from "@skryv/core-react/src/services/contextTypes";

import Button from "@skryv/core-react-vo/src/components/base/Button/Button";
import Popover, {
  popoverButtonTypes,
  popoverPositions,
  closePopovers,
  popoverSizes,
} from "@skryv/core-react-vo/src/components/base/Popover/Popover";
import EditorComponent from "@skryv/core-react-vo/src/components/form/components/wrappers/EditorComponent/EditorComponent";

import WRPFormSummary from "../WRPFormSummary/WRPFormSummary";
import { goToFirstErrorInputOnThePage } from "./wrpSectionWizardHelpers";
import "./WRPSectionWizardBase.scss";

function WRPSectionWizardBase({
  sections,
  summaryProps,
  onSectionOpened,
  onReviewModeToggle,
  onRequestCanceled,
  isCancellingRequest,
  title,
  welcomeText,
}) {
  const { gettext, xgettext } = useContext(TranslationsContext);
  const [inLastSection, setInLastSection] = useState(false);
  const [activeSectionIndex, setActiveSectionIndex] = useState(-1); // -1 = overview page
  const [activeSubSectionIndex, setActiveSubSectionIndex] = useState(-1); // -1 = not on a subsection
  const { onSaveForm } = useFormContext();

  useEffect(() => {
    if (isFunction(onSectionOpened)) onSectionOpened(activeSectionIndex);
  }, [activeSectionIndex, onSectionOpened]);

  function tryGoToNextSection(newIndex, newSubIndex = -1) {
    const activeSectionIsNotComplete =
      activeSectionIndex !== -1 &&
      activeSubSectionIndex === -1 &&
      !sections[activeSectionIndex].isComplete;
    const activeSubSectionIsNotComplete =
      activeSectionIndex !== -1 &&
      activeSubSectionIndex !== -1 &&
      !sections[activeSectionIndex].subSections[activeSubSectionIndex]
        .isComplete;
    if (activeSectionIsNotComplete || activeSubSectionIsNotComplete) {
      onReviewModeToggle(true);
      // first wait until the review mode is activated in the dom (this way, the "required" errors will be visible), then go to the error
      setTimeout(goToFirstErrorInputOnThePage, 0);
    } else {
      onReviewModeToggle(false);
      setActiveSectionAndScrollToTop(newIndex, newSubIndex);
    }
  }

  function setActiveSectionAndScrollToTop(newIndex, newSubIndex = -1) {
    setActiveSectionIndex(newIndex);
    setActiveSubSectionIndex(newSubIndex);
    setInLastSection(newIndex === sections.length - 1);
    let container = document.getElementsByClassName(
      "wrp-section-wizard-where-am-i"
    )[0];
    setTimeout(() => {
      container && container.scrollIntoView(true);
    }, 1);
  }

  function renderStep(step, index, isSubStep = false) {
    return (
      <li
        className={classnames("wrp-section-wizard-step", "vl-step", {
          "vl-step--disabled": step.isDisabled,
          "vl-step--error": !step.isDisabled && step.hasErrors,
          "vl-step--success":
            !step.isDisabled &&
            step.isComplete &&
            (!step.subSections || step.allSubSectionsComplete),
        })}
        key={step.name}
      >
        <div className="vl-step__container">
          {/* Circle and step number / icon */}
          <div className="vl-step__icon">
            {!isSubStep && (
              <>
                <span className="vl-u-visually-hidden">{gettext("Step")} </span>
                <span>{step.sectionNumber}</span>
              </>
            )}
          </div>
          <div className="vl-step__wrapper">
            {/* Step text */}
            <div className="vl-step__header">
              <div className="vl-step__header__titles">
                <h3 className="vl-step__title">{step.label}</h3>
              </div>
            </div>
            <div className="vl-step__content-wrapper">
              <div className="vl-step__content">
                <div dangerouslySetInnerHTML={{ __html: step.help }} />
              </div>
              {/* Buttons */}
              {!step.isDisabled && !step.isComplete && (
                <div>
                  <button
                    className="vl-button wrp-section-wizard-step-button"
                    onClick={() =>
                      setActiveSectionAndScrollToTop(
                        step.sectionNumber - 1,
                        isSubStep ? index : -1
                      )
                    }
                  >
                    <span className="vl-button__label">
                      {gettext("Go to this step")}
                    </span>
                  </button>
                </div>
              )}
              {!step.isDisabled && step.isComplete && (
                <div>
                  <button
                    className="vl-button vl-button--naked-action vl-button--icon-before wrp-section-wizard-step-button wrp-section-wizard-step-edit-button"
                    onClick={() =>
                      setActiveSectionAndScrollToTop(
                        step.sectionNumber - 1,
                        isSubStep ? index : -1
                      )
                    }
                  >
                    <span
                      className="vl-button__icon vl-button__icon--before vl-vi vl-vi-pencil"
                      aria-hidden="true"
                    />
                    <span className="vl-button__label">{gettext("Edit")}</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Subsections */}
        {step.subSections && step.subSections.length > 0 && (
          <div className="vl-steps vl-steps--sub vl-step__substeps">
            <ul className="vl-steps__list">
              {map(step.subSections, (step, index) =>
                renderStep(step, index, true)
              )}
            </ul>
          </div>
        )}
      </li>
    );
  }

  function renderNavigationButtons() {
    return (
      <div className="vl-col--1-1">
        <hr className="vl-u-hr vl-u-spacer--medium" />
        <div className="vl-action-group">
          {activeSectionIndex > 0 && (
            <button
              type="button"
              className="wizard-prev vl-link"
              data-vl-wizard-prev
              onClick={() => {
                onSaveForm();
                if (
                  activeSubSectionIndex === -1 &&
                  !sections[activeSectionIndex - 1].subSections
                ) {
                  setActiveSectionAndScrollToTop(activeSectionIndex - 1);
                } else if (
                  activeSubSectionIndex === -1 &&
                  sections[activeSectionIndex - 1].subSections
                ) {
                  setActiveSectionAndScrollToTop(
                    activeSectionIndex - 1,
                    sections[activeSectionIndex - 1].subSections.length - 1
                  );
                } else {
                  setActiveSectionAndScrollToTop(
                    activeSectionIndex,
                    activeSubSectionIndex - 1
                  );
                }
              }}
            >
              <i
                className="vl-link__icon vl-link__icon--before vl-vi vl-vi-arrow-left-fat"
                aria-hidden="true"
              />
              {gettext("Previous")}
            </button>
          )}

          {activeSectionIndex < sections.length - 1 && (
            <Button
              data-vl-wizard-next
              className="wizard-next"
              labels={{ active: gettext("Next") }}
              action={() => {
                onSaveForm();
                if (
                  !sections[activeSectionIndex].subSections ||
                  sections[activeSectionIndex].subSections.length ===
                    activeSubSectionIndex + 1
                ) {
                  tryGoToNextSection(activeSectionIndex + 1);
                } else {
                  tryGoToNextSection(
                    activeSectionIndex,
                    activeSubSectionIndex + 1
                  );
                }
              }}
            />
          )}
        </div>
      </div>
    );
  }

  function renderCancelRequestButton() {
    return (
      <Popover
        toggleButtonType={popoverButtonTypes.NAKED}
        isBusy={isCancellingRequest}
        position={popoverPositions.RIGHT}
        size={popoverSizes.LARGE}
        toggleButtonContent={
          <>
            <span
              className="vl-button__icon vl-button__icon--before vl-vi vl-vi-trash"
              aria-hidden="true"
            />
            <span className="vl-button__label">
              {!isCancellingRequest && gettext("Cancel request")}
              {isCancellingRequest && gettext("Cancelling request")}
            </span>
          </>
        }
      >
        <h4 className="vl-u-text--bold">
          {gettext("Are you sure you want to cancel this request?")}
        </h4>
        <p>
          {gettext(
            "Your request will be removed and all of your data will be lost"
          )}
        </p>

        <div className="vl-action-group vl-action-group--align-right vl-u-spacer-top--medium">
          <button
            className="vl-button vl-button--icon-before delete-item-button"
            onClick={() => {
              onRequestCanceled();
              closePopovers();
            }}
          >
            <i
              className="vl-button__icon vl-button__icon--before vl-vi vl-vi-trash"
              aria-hidden="true"
            ></i>
            <span className="vl-button__label">{gettext("Yes, cancel")}</span>
          </button>
          <button
            className="vl-button vl-button--secondary"
            onClick={() => closePopovers()}
          >
            <span className="vl-button__label">
              {gettext("No, keep going")}
            </span>
          </button>
        </div>
      </Popover>
    );
  }

  function renderWhereAmI() {
    const activeSection = sections[activeSectionIndex];
    const activeSubSection =
      activeSubSectionIndex !== -1 &&
      sections[activeSectionIndex].subSections[activeSubSectionIndex];
    return (
      activeSectionIndex !== -1 && (
        <div className="wrp-section-wizard-where-am-i">
          <div className="wrp-section-wizard-where-am-i-section-header">
            <div className="vl-typography">
              <span className="vl-annotation">
                {xgettext("Step", "Step x of y") +
                  " " +
                  activeSection.sectionNumber +
                  " " +
                  xgettext("of", "Step x of y") +
                  " " +
                  sections.length}
              </span>
              <h1 className="wrp-section-wizard-where-am-i-title vl-title vl-title--h1">
                {activeSection.label}
              </h1>
            </div>
            <div className="wrp-section-wizard-where-am-i-section-header-buttons">
              {renderCancelRequestButton()}
              <button
                className="vl-button vl-button--tertiary vl-button--icon-before"
                onClick={() => {
                  setActiveSectionIndex(-1);
                  setActiveSubSectionIndex(-1);
                }}
              >
                <span
                  className="vl-button__icon vl-button__icon--before vl-vi vl-vi-nav-left-double"
                  aria-hidden="true"
                />
                <span className="vl-button__label">
                  {gettext("Back to overview")}
                </span>
              </button>
            </div>
          </div>
          <hr
            className={classnames("vl-u-hr", {
              "vl-u-spacer--small": activeSubSectionIndex !== -1,
            })}
          />
          {activeSubSectionIndex !== -1 && (
            <div className="wrp-section-wizard-where-am-i-category-header vl-u-spacer--small">
              <div className="vl-typography">
                <span className="vl-annotation">
                  {xgettext("Category", "Category x of y") +
                    " " +
                    activeSubSection.subSectionNumber +
                    " " +
                    xgettext("of", "Category x of y") +
                    " " +
                    activeSection.subSections.length}
                </span>
                <h2 className="wrp-section-wizard-where-am-i-title vl-title vl-title--h2">
                  {activeSubSection.label}
                </h2>
              </div>
            </div>
          )}
        </div>
      )
    );
  }

  function renderStepsOverview() {
    return (
      <>
        {/* Welcome text */}
        <div className="wrp-section-wizard-where-am-i">
          <div className="wrp-section-wizard-where-am-i-section-header steps-overview">
            <div className="vl-typography">
              <h1 className="wrp-section-wizard-where-am-i-title vl-title vl-title--h1">
                {title}
              </h1>
            </div>
            <div>{renderCancelRequestButton()}</div>
          </div>
          <hr className="vl-u-hr vl-u-spacer-bottom--small" />
        </div>
        {welcomeText && (
          <div className="vl-typography vl-u-spacer--large">{welcomeText}</div>
        )}

        {/* Steps */}
        <div className="vl-typography">
          <h2 className="wrp-section-wizard-where-am-i-title vl-title vl-title--h2">
            Stappenoverzicht
          </h2>
        </div>
        <div className="vl-steps vl-steps--has-line vl-steps--last-step-no-line">
          <ul className="vl-steps__list steps">
            {map(sections, (step, index) => renderStep(step, index))}
          </ul>
        </div>
      </>
    );
  }

  function renderStepsContent() {
    const activeContent =
      activeSubSectionIndex > -1
        ? sections[activeSectionIndex].subSections[activeSubSectionIndex]
        : sections[activeSectionIndex];
    return (
      <>
        {renderWhereAmI()}
        {inLastSection && <WRPFormSummary {...summaryProps} />}
        <div className="wrp-section-wizard-pane">
          <EditorComponent
            component={activeContent.component}
            manipulator={activeContent.manipulator}
            config={activeContent.config}
          />
        </div>
        {renderNavigationButtons()}
      </>
    );
  }

  return (
    <>
      {activeSectionIndex === -1 ? renderStepsOverview() : renderStepsContent()}
    </>
  );
}

WRPSectionWizardBase.propTypes = {
  onSectionOpened: PropTypes.func,
  summaryProps: PropTypes.shape({}),
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      content: PropTypes.func,
      isComplete: PropTypes.bool,
      sectionNumber: PropTypes.number,
      subSections: PropTypes.arrayOf(
        PropTypes.shape({
          isComplete: PropTypes.bool,
          sectionNumber: PropTypes.number,
        })
      ),
    })
  ).isRequired,
  onReviewModeToggle: PropTypes.func,
  onRequestCanceled: PropTypes.func,
  isCancellingRequest: PropTypes.bool,
  title: PropTypes.string,
  welcomeText: PropTypes.element,
};

export default WRPSectionWizardBase;
