import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { filter, keys, map, take } from "lodash";

import { useSectionWizard } from "@skryv/core-react/src/components/form/editor/SectionWizard/sectionWizardHelpers";
import EditorComponent from "@skryv/core-react-vo/src/components/form/components/wrappers/EditorComponent/EditorComponent";

import WRPSectionWizardBase from "./WRPSectionWizardBase";
import {
  calculateWhetherSectionShouldBeDisabled,
  doesSectionHaveErrors,
  doesSectionHaveErrorsOtherThanRequired,
  goToFirstErrorInputOnThePage,
} from "./wrpSectionWizardHelpers";

function WRPSectionWizard({
  manipulator,
  component,
  config,
  readOnly,
  onlyWhenExpressionsForCategories,
  selectFieldsetName,
  buildSectionStructure,
  title,
  welcomeText,
}) {
  const parenInReviewMode = config.currentDocument.getIsReviewing();
  const [inReviewMode, setInReviewMode] = useState(parenInReviewMode || false);
  const [isCancellingRequest, setIsCancellingRequest] = useState(false);
  const { onSectionOpened } = useSectionWizard(
    {
      sections: component.sections,
      config,
    },
    () => {}
  );

  useEffect(() => {
    // the parent will set review mode to true when the user tries to submit but there are still errors somewhere in the form
    // in this custom WRPSectionWizard a user is blocked to go to the next section if there are errors in the current section
    // so if a user is able to click the submit button (only on the last page), it means there are no errors left in the form except on the last section
    // so we focus on these errors in the last section; and don't show the general 'check your form modal' (see wrpTaskPage)
    if (parenInReviewMode) {
      goToFirstErrorInputOnThePage();
    }
  }, [parenInReviewMode]);

  const newConfig = {
    ...config,
    currentDocument: {
      ...config.currentDocument,
      getIsReviewing: () => parenInReviewMode || inReviewMode,
    },
  };

  const summaryProps = {
    manipulator,
    component: {
      ...component,
      components: take(component.components, 5),
      inputOptions: { ...component.inputOptions, customComponentName: "dummy" },
    },
    config,
  };

  function sectionToWizardInformation(index) {
    const section = component.sections[index];
    return {
      sectionNumber: section.sectionNumber[0],
      key: section.name,
      name: section.name,
      label: section.title,
      help: section.help,
      component: section,
      manipulator: manipulator,
      config: newConfig,
      hasErrors: doesSectionHaveErrorsOtherThanRequired(section, manipulator),
      isComplete: !doesSectionHaveErrors(section, manipulator),
      isDisabled: false,
    };
  }

  function categoriesSectionToWizardInformation(sectionIndex) {
    const selectCategory = {
      manipulator: manipulator.propertyManipulators[selectFieldsetName],
      component: component.sections[sectionIndex].components[0],
    };

    const allCategories = map(
      keys(onlyWhenExpressionsForCategories),
      (categoryFieldsetName, index) => ({
        manipulator: manipulator.propertyManipulators[categoryFieldsetName],
        component: component.sections[sectionIndex].components[1 + index],
        isActive:
          manipulator.computedExpressions[
            onlyWhenExpressionsForCategories[categoryFieldsetName]
          ],
      })
    );

    const categorySections = map(
      filter(allCategories, "isActive"),
      (activeCategory, index) => {
        return {
          sectionNumber: component.sections[sectionIndex].sectionNumber[0],
          subSectionNumber: index + 1,
          name: activeCategory.component.inputOptions.name,
          key: activeCategory.component.inputOptions.name,
          label: activeCategory.component.label,
          help: activeCategory.component.help,
          component: activeCategory.component,
          manipulator: activeCategory.manipulator,
          config: newConfig,
          hasErrors: doesSectionHaveErrorsOtherThanRequired(
            activeCategory.component,
            activeCategory.manipulator
          ),
          isComplete: !doesSectionHaveErrors(
            activeCategory.component,
            activeCategory.manipulator
          ),
          isDisabled: false,
        };
      }
    );

    return {
      sectionNumber: component.sections[sectionIndex].sectionNumber[0],
      name: selectCategory.component.inputOptions.name,
      key: selectCategory.component.inputOptions.name,
      label: selectCategory.component.label,
      help: component.sections[sectionIndex].help,
      component: selectCategory.component,
      manipulator: selectCategory.manipulator,
      config: newConfig,
      subSections: categorySections,
      // the parent 'section' is in error mode when there are errors in the fieldset "selectFieldsetName" (~ questions on 'parent' page)
      // this does not take errors in the other fieldsets (~ other 'subsections') into account
      hasErrors: doesSectionHaveErrorsOtherThanRequired(
        component.sections[sectionIndex].components[0],
        selectCategory.manipulator
      ),
      // the parent 'section' is in complete mode when there are no errors in the fieldset "selectFieldsetName" (~ questions on 'parent' page)
      // this does not take errors in the other fieldsets (~ other 'subsections') into account
      isComplete: !doesSectionHaveErrors(
        component.sections[sectionIndex].components[0],
        selectCategory.manipulator
      ),
      // the parent 'section' is completed when there are no errors in the full section
      // this takes the fieldset "selectFieldsetName" into account plus all other fieldsets (~ other 'subsections')
      allSubSectionsComplete: !doesSectionHaveErrors(
        component.sections[sectionIndex],
        manipulator
      ),
      isDisabled: false,
    };
  }

  function wizardSections() {
    const sections = buildSectionStructure(
      sectionToWizardInformation,
      categoriesSectionToWizardInformation
    );

    return calculateWhetherSectionShouldBeDisabled(sections);
  }

  function onReviewModeToggle(value) {
    setInReviewMode(value);
  }

  function onRequestCanceled() {
    setIsCancellingRequest(true);
    config.customRequest
      .deleteDossier()
      .finally(() => setIsCancellingRequest(false));
  }

  // if readOnly, we know that we are inside the summary; we don't want the WRP Section wizard in that case
  return readOnly ? (
    <EditorComponent {...summaryProps} />
  ) : (
    <WRPSectionWizardBase
      sections={wizardSections()}
      summaryProps={summaryProps}
      onSectionOpened={onSectionOpened}
      config={config}
      onReviewModeToggle={onReviewModeToggle}
      onRequestCanceled={onRequestCanceled}
      isCancellingRequest={isCancellingRequest}
      title={title}
      welcomeText={welcomeText}
    />
  );
}

WRPSectionWizard.propTypes = {
  component: PropTypes.shape({
    label: PropTypes.string,
    components: PropTypes.array,
    sections: PropTypes.array,
    inputOptions: PropTypes.shape({}),
  }).isRequired,
  manipulator: PropTypes.object.isRequired,
  config: PropTypes.shape({
    currentDocument: PropTypes.shape({ getIsReviewing: PropTypes.func }),
    customRequest: PropTypes.shape({ deleteDossier: PropTypes.func }),
  }).isRequired,
  nested: PropTypes.bool,
  readOnly: PropTypes.bool,
  onlyWhenExpressionsForCategories: PropTypes.shape({
    binnenrenovatie: PropTypes.string,
    muurrenovatie: PropTypes.string,
    dakrenovatie: PropTypes.string,
    technischeInstallaties: PropTypes.string,
    gascondensatieketel: PropTypes.string,
    buitenschrijnwerkGlas: PropTypes.string,
    vloerrenovatie: PropTypes.string,
    warmtepomp: PropTypes.string,
    warmtepompboiler: PropTypes.string,
    zonneboiler: PropTypes.string,
    zonnepanelen: PropTypes.string,
  }),
  selectFieldsetName: PropTypes.string,
  buildSectionStructure: PropTypes.func,
  title: PropTypes.string,
  welcomeText: PropTypes.element,
};

export default WRPSectionWizard;
