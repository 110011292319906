import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { postscribePromise } from "../services/postscribe";
import { map } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import PageContent from "@skryv/core-react-vo/src/components/layout/Content/Content";
import CoreHeader from "@skryv/core-react-vo/src/components/layout/Header/Header";

import { loginUser } from "@skryv/core-react/src/core/store/actions/authentication";
import { isAuthenticated } from "@skryv/core-react/src/core/store/selectors/users";
import { pageNames } from "@skryv/core-react/src/config/pages";

import {
  fetchBurgerprofielConfiguration,
  fetchUnresolvedIncidents,
  fetchActiveMaintenance,
  fetchUpcomingMaintenance,
} from "../store/actions";
import {
  hasLoadedBurgerprofiel,
  hasValidBurgerprofielHeader,
  selectBurgerprofielHeader,
  selectUnresolvedIncidents,
  selectActiveMaintenances,
  selectScheduledMaintenances,
} from "../store/selectors";

import { mapIncidentToNotification } from "./incidentHelpers";
import IncidentNotifications from "./IncidentNotifications";

export default function WrpHeader(props) {
  const HEADER_SELECTOR = "header-script";
  const dispatch = useDispatch();
  const [shouldShowProfile, setShouldShowProfile] = useState(false);
  const [incidents, setIncidents] = useState([]);
  const shouldShowUpcomingMaintenances =
    props.$state$.name === pageNames.DASHBOARD ||
    props.$state$.name === pageNames.DOSSIER;

  useEffect(() => {
    dispatch(loginUser());
    dispatch(fetchBurgerprofielConfiguration());
    dispatch(fetchUnresolvedIncidents());
    dispatch(fetchActiveMaintenance());

    if (shouldShowUpcomingMaintenances) {
      dispatch(fetchUpcomingMaintenance());
    }
  }, [dispatch, shouldShowUpcomingMaintenances]);

  const unresolvedIncidents = useSelector(selectUnresolvedIncidents);
  const activeMaintenances = useSelector(selectActiveMaintenances);
  const scheduledMaintenances = useSelector(selectScheduledMaintenances);

  useEffect(() => {
    setIncidents([
      ...map(unresolvedIncidents, (incident) =>
        mapIncidentToNotification(incident)
      ),
      ...map(activeMaintenances, (incident) =>
        mapIncidentToNotification(incident)
      ),
      ...map(
        shouldShowUpcomingMaintenances ? scheduledMaintenances : [],
        (incident) => mapIncidentToNotification(incident)
      ),
    ]);
  }, [
    unresolvedIncidents,
    activeMaintenances,
    scheduledMaintenances,
    shouldShowUpcomingMaintenances,
  ]);

  const hasActiveSession = useSelector(isAuthenticated);
  const hasLoadedConfiguration = useSelector(hasLoadedBurgerprofiel);
  const hasValidConfiguration = useSelector(hasValidBurgerprofielHeader);
  const embedCode = useSelector(selectBurgerprofielHeader);
  const hasRenderedHeader = () => {
    const headerElement = document.getElementById("header-script");
    return headerElement && headerElement.children.length > 0;
  };
  const loadBurgerprofielHeader = (embedCode, hasActiveSession) => {
    const burgerprofielPolyfillSrc =
      "https://prod.widgets.burgerprofiel.vlaanderen.be/api/v1/node_modules/@govflanders/vl-widget-polyfill/dist/index.js";
    const burgerprofielClientSrc =
      "https://prod.widgets.burgerprofiel.vlaanderen.be/api/v1/node_modules/@govflanders/vl-widget-client/dist/index.js";

    return Promise.all([
      postscribePromise(`#${HEADER_SELECTOR}`, burgerprofielPolyfillSrc),
      postscribePromise(`#${HEADER_SELECTOR}`, burgerprofielClientSrc),
    ])
      .then(() => postscribePromise(`#${HEADER_SELECTOR}`, embedCode))
      .then(() => {
        window.vl.widget.client.capture((widget) => {
          widget.getExtension("citizen_profile.session").then((session) => {
            session.configure({
              active: hasActiveSession,
              endpoints: {
                loginUrl: "/api/login/frontoffice",
                logoutUrl: "/api/logout",
                switchCapacityUrl: "/api/wisselen-van-account",
              },
            });
          });
        });
      });
  };

  useEffect(() => {
    if (!hasActiveSession) return;
    if (!hasLoadedConfiguration) return;
    if (hasRenderedHeader()) return;
    if (!hasValidConfiguration) {
      setShouldShowProfile(true);
      return;
    }

    loadBurgerprofielHeader(embedCode, hasActiveSession);
  }, [
    hasLoadedConfiguration,
    hasValidConfiguration,
    embedCode,
    hasActiveSession,
  ]);

  return (
    <>
      <CoreHeader
        {...props}
        title={"Loket Mijn Verbouwpremie"}
        shouldShowProfile={shouldShowProfile}
      ></CoreHeader>

      {incidents && incidents.length > 0 && (
        <PageContent contentName="notifications">
          <IncidentNotifications incidents={incidents} />
        </PageContent>
      )}
    </>
  );
}

WrpHeader.propTypes = {
  ...CoreHeader.propTypes,
  $state$: PropTypes.shape({
    name: PropTypes.string,
  }),
};
