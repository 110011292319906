import React from "react";
import WRPSectionWizard from "../WRPSectionWizard";

export default function MVLSectionWizard(props) {
  const selectFieldsetName = "selectieCategorieenFieldset";

  const onlyWhenExpressionsForCategories = {
    binnenrenovatie: "binnenrenovatieIsAangevraagd",
    muurrenovatie: "muurIsAangevraagd",
    dakrenovatie: "dakrenovatieIsAangevraagd",
    technischeInstallaties: "elektriciteitEnSanitairAangevraagd",
    gascondensatieketel: "gascondensatieketelIsAangevraagd",
    buitenschrijnwerkGlas: "ramenEnDeurenIsAangevraagd",
    vloerrenovatie: "vloerIsAangevraagd",
    warmtepomp: "warmtepompIsAangevraagd",
    warmtepompboiler: "warmtepompboilerIsAangevraagd",
    zonneboiler: "zonneboilerIsAangevraagd",
    zonnepanelen: "zonnepanelenIsAangevraagd",
  };

  function buildSectionStructure(
    buildDefaultSection,
    buildSelectCategoriesSection
  ) {
    return [
      buildDefaultSection(0),
      buildDefaultSection(1),
      buildSelectCategoriesSection(2),
      buildDefaultSection(3),
      buildDefaultSection(4),
      buildDefaultSection(5),
      buildDefaultSection(6),
      buildDefaultSection(7),
    ];
  }

  const title = "Aanvraag Mijn VerbouwLening";

  const welcomeText = (
    <>
      <p>
        Via onderstaande stappen kunt u Mijn VerbouwLening, een voordelige*
        lening voor renovatie en energiebesparende maatregelen, aanvragen.
      </p>
      <p>
        <b>Controleer</b> op voorhand of u voldoet aan alle {""}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          target="_blank"
          href="https://www.vlaanderen.be/bouwen-wonen-en-energie/lenen/mijn-verbouwlening"
          className="vl-link"
          rel="noreferrer"
        >
          voorwaarden
          <span className="vl-u-visually-hidden">(opent in nieuw venster)</span>
          <span
            className="vl-link__icon vl-link__icon--after vl-link__icon--light vl-vi vl-vi-external"
            aria-hidden="true"
          ></span>
        </a>
        om in aanmerking te komen.
      </p>
      <p>
        U kunt uw lening aanvragen via dit formulier op basis van offertes. Uw
        Energiehuis beoordeelt deze aanvraag in een volgende fase. Het is
        mogelijk om de lening in meerdere schijven op te nemen (met een totaal
        maximumbedrag van 60.000 euro, dat binnen de 36 maanden na ondertekening
        van het contract opgenomen en op maximaal 25 jaar terugbetaald moet
        worden). De uitbetaling gebeurt op basis van de ingediende{" "}
        <b>(voorschot)facturen</b>.
      </p>
      <p>
        U moet alle documenten <b>digitaal bijvoegen</b>. Zorg dat u deze ter
        beschikking hebt alvorens u start met een aanvraag.
      </p>
      <p>Overzicht documenten:</p>
      <ul>
        <li>offertes</li>
        <li>
          bewijsstukken (zoals bijvoorbeeld attest beschermde afnemer, indien
          nodig)
        </li>
        <li>recente loonbrieven</li>
        <li>Kopie van uw ID kaart</li>
      </ul>
      <p>
        Lees meer over {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          target="_blank"
          href="https://www.vlaanderen.be/bouwen-wonen-en-energie/lenen/mijn-verbouwlening"
          className="vl-link"
          rel="noreferrer"
        >
          MijnVerbouwLening
          <span className="vl-u-visually-hidden">(opent in nieuw venster)</span>
          <span
            className="vl-link__icon vl-link__icon--after vl-link__icon--light vl-vi vl-vi-external"
            aria-hidden="true"
          ></span>
        </a>
        en ons {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          target="_blank"
          href="https://www.vlaanderen.be/bouwen-wonen-en-energie/bouwen-en-verbouwen/premies-en-belastingvoordelen/mijn-verbouwpremie/privacyverklaring"
          className="vl-link"
          rel="noreferrer"
        >
          Privacybeleid
          <span className="vl-u-visually-hidden">(opent in nieuw venster)</span>
          <span
            className="vl-link__icon vl-link__icon--after vl-link__icon--light vl-vi vl-vi-external"
            aria-hidden="true"
          ></span>
        </a>
      </p>
      <p>*Opgelet: Geld lenen, kost ook geld! </p>
    </>
  );

  return (
    <WRPSectionWizard
      onlyWhenExpressionsForCategories={onlyWhenExpressionsForCategories}
      selectFieldsetName={selectFieldsetName}
      buildSectionStructure={buildSectionStructure}
      title={title}
      welcomeText={welcomeText}
      {...props}
    />
  );
}
