import moment from "moment";

import {
  SkryvFrontOfficeApp,
  navigation,
  form,
  translation,
} from "@skryv/core-react";
import "@skryv/core-react-vo";
import { registerDefaultRouting } from "@skryv/core-react-vo/src/config/routing";
import { registerDefaultTranslations } from "@skryv/core-react-vo/src/config/translations";

import { layoutNames } from "@skryv/core-react/src/config/layouts";
import { pageNames } from "@skryv/core-react/src/config/pages";
import router from "@skryv/core-react/src/services/router";
import { deleteDossier } from "@skryv/core-react/src/core/store/actions/dossiers";

import translations from "../translations";

import customReduxStore from "./store";
import { fetchAddressByEanCode, fetchEboxStatus } from "./store/actions";

import "./theme/overrides.scss";

import CategoryTiles from "./components/form/CategoryTiles/CategoryTiles";
import RoofParts from "./components/form/RoofParts/RoofParts";
import AsSection from "./components/form/AsSection/AsSection";
import WRPSectionWizard from "./components/form/WRPSectionWizard/WRPSectionWizard";
import MVLSectionWizard from "./components/form/WRPSectionWizard/MVLSectionWizard/MVLSectionWizard";
import MVPSectionWizard from "./components/form/WRPSectionWizard/MVPSectionWizard/MVPSectionWizard";
import CommunicationPreference from "./components/form/CommunicationPreference/CommunicationPreference";
import EANWithAddress from "./components/form/EANWithAddress/EANWithAddress";
import FullWidth from "./components/form/FullWidth/FullWidth";
import AttachmentsTable from "./components/form/AttachmentsTable/AttachmentsTable";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Dashboard from "./components/Dashboard";
import TaskPage from "./components/TaskPage";
import DossierPage from "./components/DossierPage";

moment.locale("nl");
const customizedTranslation = registerDefaultTranslations(translation)
  .locale("nl_BE")
  .add("nl_BE", translations.nl_BE);

window.useNewES = true; //use secure elasticsearch

function checkEboxStatus(dossierId) {
  return () => {
    if (!dossierId) {
      console.error(
        "No dossier id was passed, could not retrieve the status of the ebox"
      );
    }
    return customReduxStore
      .dispatch(fetchEboxStatus(dossierId))
      .then(({ api }) => {
        return api.response.data;
      });
  };
}

function retrieveEANResponse(ean) {
  if (!ean) {
    console.error("No ean was passed, could not retrieve an address");
    return new Promise((resolve) => resolve({}));
  }

  return customReduxStore
    .dispatch(fetchAddressByEanCode(ean))
    .then(({ api }) => api.response.data);
}

function deleteDossierFromForm(dossierId) {
  return () => {
    return customReduxStore
      .dispatch(deleteDossier(dossierId))
      .then(() => router.goToState(pageNames.DASHBOARD));
  };
}

form
  .setFormConfig((context) => ({
    showSectionWizard: true,
    showDocumentTitle: false,
    attachments: {
      acceptedMimeTypes: ["pdf"],
    },
    customRequest: {
      checkEboxStatus: checkEboxStatus(context.dossierId),
      retrieveEANResponse,
      deleteDossier: deleteDossierFromForm(context.dossierId),
    },
  }))
  .addCustomComponent("categoryTiles", CategoryTiles)
  .addCustomComponent("roofParts", RoofParts)
  .addCustomComponent("WRPSectionWizard", WRPSectionWizard)
  .addCustomComponent("MVLSectionWizard", MVLSectionWizard)
  .addCustomComponent("MVPSectionWizard", MVPSectionWizard)
  .addCustomComponent("AsSection", AsSection)
  .addCustomComponent("CommunicationPreference", CommunicationPreference)
  .addCustomComponent("eanWithAddress", EANWithAddress)
  .addCustomComponent("fullWidth", FullWidth)
  .addCustomComponent("AttachmentsTable", AttachmentsTable);

const customNavigation = registerDefaultRouting(navigation)
  .layout(layoutNames.FRONTOFFICE, {
    extend: true,
    views: {
      header: Header,
      footer: Footer,
    },
  })
  .page(pageNames.DASHBOARD, {
    extend: true,
    views: {
      content: Dashboard,
    },
  })
  .page(pageNames.TASK, {
    extend: true,
    views: {
      content: TaskPage,
    },
  })
  .page(pageNames.DOSSIER, {
    extend: true,
    views: {
      content: DossierPage,
    },
  });

class App extends SkryvFrontOfficeApp {
  constructor() {
    super(customReduxStore);
  }
}

App.createSkryvApp()
  .customize(customizedTranslation)
  .customize(form)
  .customize(customNavigation)
  .initialize(document.getElementById("root"));
