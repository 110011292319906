import React, { useEffect, useState } from "react";
import moment from "moment";
import { get, isFunction, map } from "lodash";
import classnames from "classnames";
import { connect } from "react-redux";

import {
  dossierPageWrapper as coreDossierPageWrapper,
  externalProps,
  internalProps,
} from "@skryv/core-react/src/components/dossier/DossierPage/DossierPage";
import { DossierPage as CoreDossierPage } from "@skryv/core-react-vo/src/components/dossier/DossierPage/DossierPage";
import { notificationTypes } from "@skryv/core-react/src/components/base/Notification/Notification";
import router from "@skryv/core-react/src/services/router";
import { pageNames } from "@skryv/core-react/src/config/pages";

import contactInformation from "../constants/contactInformation";
import documentDefinitionKeys from "../constants/documentDefinitionKeys";
import milestoneDefinitionKeys from "../constants/milestoneDefinitionKeys";
import categoryIcons from "../constants/iconsForCategories";

import { fetchWrpProfile } from "../store/actions";
import { is1700Employee } from "../store/selectors";

const notitificationDossierDecided = () => ({
  id: "DOSSIER_DECIDED",
  title: "Dossier beslist",
  message: `We hebben uw dossier volledig behandeld. Bij 'dossierdetails' kan u de beslissing van elke aangevraagde categorie raadplegen. Bij 'documenten en downloads' kan u een detail van de beslissing consulteren.`,
  type: notificationTypes.SUCCESS,
});

const notitificationBeroepDecided = () => ({
  id: "BEROEP_DECIDED",
  title: "Beroep beslist",
  message: `We hebben uw beroep volledig behandeld. Bij 'dossierdetails' kan u de beslissing van uw beroep raadplegen. Bij 'documenten en downloads' kan u een detail van de beslissing consulteren.`,
  type: notificationTypes.SUCCESS,
});

const notitificationAdditionalAttachmentsDelivered = ({
  additionalAttachmentsDelivered,
  beslissingsdatum,
}) => ({
  id: "ADDITIONAL_ATTACHMENTS_DELIVERED",
  title: "Gevraagde informatie aangeleverd",
  message: `We hebben de gevraagde informatie goed ontvangen op <strong>${moment(
    additionalAttachmentsDelivered
  ).format(
    "D MMMM YYYY"
  )}</strong>. We nemen uw dossier opnieuw in behandeling en sturen u uiterlijk op ${moment(
    beslissingsdatum
  ).format("D MMMM YYYY")} de beslissing over uw dossier.`,
  type: notificationTypes.SUCCESS,
});

const notitificationAdditionalAttachmentsDeliveredBeroep = ({
  additionalAttachmentsDeliveredBeroep,
}) => ({
  id: "ADDITIONAL_ATTACHMENTS_DELIVERED_BEROEP",
  title: "Gevraagde informatie aangeleverd",
  message: `We hebben de gevraagde informatie goed ontvangen op <strong>${moment(
    additionalAttachmentsDeliveredBeroep
  ).format(
    "D MMMM YYYY"
  )}</strong>. We nemen uw beroep opnieuw in behandeling.`,
  type: notificationTypes.SUCCESS,
});

const notitificationAppealWaitingTooLong = () => ({
  id: "BEROEP_TEGEN_STILZITTEN_STARTED",
  title: "Beroep tegen stilzitten ingediend",
  message: `We ontvingen uw beroep tegen het stilzitten (het ontbreken van een beslissing) van onze diensten in verband met uw aanvraag voor Mijn VerbouwPremie. U ontvangt hiervan een bevestiging via uw communicatie voorkeur. We behandelen uw dossier zo snel mogelijk.`,
  type: notificationTypes.SUCCESS,
});

const notitificationAdditionalAttachmentsNeeded = ({
  additionalAttachmentsRequested,
}) => ({
  id: "ADDITIONAL_ATTACHMENTS_NEEDED",
  title: "Dossier onvolledig",
  message: `Uw dossier kan niet worden afgewerkt. Bepaalde informatie of bijlagen ontbreken. Laad de ontbrekende informatie/bijlage tegen <strong>${moment(
    additionalAttachmentsRequested
  ).format(
    "D MMMM YYYY"
  )}</strong> op via de beschikbare dossieractie 'Laad bijkomende stukken op'. Als u niet reageert op onze vraag, handelen we uw dossier af met de informatie waarover we nu beschikken. U loopt dan het risico dat u geen premie krijgt terwijl u er mogelijk wel recht op hebt.`,
  type: notificationTypes.WARNING,
});

const notitificationAdditionalAttachmentsNeededBeroep = ({
  additionalAttachmentsRequested,
}) => ({
  id: "ADDITIONAL_ATTACHMENTS_NEEDED_BEROEP",
  title: "Dossier onvolledig",
  message: `Uw beroep kan niet worden afgewerkt. Bepaalde informatie of bijlagen ontbreken. Laad de ontbrekende informatie/bijlage tegen <strong>${moment(
    additionalAttachmentsRequested
  ).format(
    "D MMMM YYYY"
  )}</strong> op via de beschikbare dossieractie 'Laad bijkomende stukken op'. Als u niet reageert op onze vraag, handelen we uw beroep af met de informatie waarover we nu beschikken.`,
  type: notificationTypes.WARNING,
});

const notificationRequestNotSubmitted = () => ({
  id: "REQUEST_INITIATED",
  title: "Aanvraag nog niet ingediend",
  message:
    "Gelieve het formulier te vervolledigen en vervolgens in te dienen. Uw niet-ingediende aanvraag (concept) wordt 6 maanden na aanmaak automatisch verwijderd. U kunt het concept steeds zelf annuleren door onder ‘vervolledig aanvraag’ op ‘uitvoeren’ te klikken en vervolgens bovenaan ‘Annuleer aanvraag’ te kiezen.",
  type: notificationTypes.WARNING,
});

const notificationRequestSubmitted = ({ aanvraagdatum, beslissingsdatum }) => ({
  id: "BO_TREATMENT_STARTED",
  title: "Aanvraag ingediend",
  message: `We hebben uw aanvraag van Mijn VerbouwPremie goed ontvangen op ${moment(
    aanvraagdatum
  ).format(
    "D MMMM YYYY"
  )} en bekijken of uw dossier alle noodzakelijke documenten bevat. We doen onze uiterste best om uw aanvraag zo snel mogelijk te behandelen maar gezien het grote aantal aanvragen zijn er nog vele wachtenden voor u. Mocht u tegen <strong>${moment(
    beslissingsdatum
  ).format(
    "D MMMM YYYY"
  )}</strong> nog geen antwoord hebben gekregen kunt u beroep aantekenen wegens het uitblijven van de beslissing. U kunt uw dossiergegevens raadplegen en downloaden via 'documenten en downloads' op dit dossieroverzicht.`,
  type: notificationTypes.SUCCESS,
});

const notificationBeroepRequestSubmitted = ({
  aanvraagdatum,
  beroepbeslissingsdatum,
}) => ({
  id: "FO_BEROEP_STARTED",
  title: "Beroep ingediend",
  message: `We hebben uw beroep tegen de beslissing goed ontvangen op ${moment(
    aanvraagdatum
  ).format(
    "D MMMM YYYY"
  )}. We onderzoeken uw beroep en gaan na of het gegrond is. Uiterlijk op <strong>${moment(
    beroepbeslissingsdatum
  ).format("D MMMM YYYY")}</strong>  ontvangt u onze beslissing.`,
  type: notificationTypes.SUCCESS,
});

const notificationToModifyRequestor = ({
  deadlineForModificationRequestor,
}) => ({
  id: "REQUESTOR_CAN_MODIFY_REQUESTOR",
  title: "Wijzigen aanvrager",
  message: `U vraagt een premie aan als eigenaar-bewoner. Na controle stellen we vast dat u niet de juiste eigendomsrechten heeft en/of niet gedomicilieerd bent in de premiewoning. Hierdoor behoort u automatisch toe aan de doelgroep ‘niet eigenaar-bewoner’. Bijgevolg zal u dan ook de premie voor deze doelgroep ontvangen. Dit is de laagste premie voor uitsluitend energiebesparende werken.</br></br>
   Als een ander meerderjarig persoon met wie u samenwoont wel beschikt over de juiste eigendomsrechten én gedomicilieerd is in de premiewoning, kan deze persoon een nieuwe premieaanvraag indienen zodat er mogelijk een hogere premie kan worden toegekend. Meer informatie over wie welke premies kan aanvragen en op welke premie elke doelgroep recht heeft, kunt u terugvinden op  <a href="www.mijnverbouwpremie.be">www.mijnverbouwpremie.be</a> en de simulator op deze pagina.</br></br>
   Gelieve ten laatste <strong>${moment(
     deadlineForModificationRequestor
   ).format(
     "D MMMM YYYY"
   )}</strong> aan te geven via de beschikbare dossieractie 'Wijzigen aanvrager?' op dit dossieroverzicht of u met deze aanvraag wenst door te gaan of dat een ander persoon die eigenaar-bewoner is de premieaanvraag zal indienen.`,
  type: notificationTypes.WARNING,
});

const notificationsForMilestones = {
  [milestoneDefinitionKeys.REQUEST_INITIATED]: notificationRequestNotSubmitted,
  [milestoneDefinitionKeys.FETCH_DATA_CITIZEN]: notificationRequestNotSubmitted,
  [milestoneDefinitionKeys.FETCH_DATA_COMPANY]: notificationRequestNotSubmitted,
  [milestoneDefinitionKeys.FILLING_IN_REQUEST]: notificationRequestNotSubmitted,
  [milestoneDefinitionKeys.BEROEP_SUBMITTED]:
    notificationBeroepRequestSubmitted,
  [milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_REQUESTED]:
    notitificationAdditionalAttachmentsNeeded,
  [milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_REQUESTED_BEROEP]:
    notitificationAdditionalAttachmentsNeededBeroep,
  [milestoneDefinitionKeys.CHANGE_OF_REQUESTOR_REQUESTED]:
    notificationToModifyRequestor,
  [milestoneDefinitionKeys.BEROEP_TEGEN_STILZITTEN_INGEDIEND]:
    notitificationAppealWaitingTooLong,
  [milestoneDefinitionKeys.DECISION_LETTER_TO_CLIENT]:
    notitificationDossierDecided,
  [milestoneDefinitionKeys.DECISION_LETTER_TO_CLIENT_BEROEP]:
    notitificationBeroepDecided,
  [milestoneDefinitionKeys.BEROEP_NOT_VALID]: notitificationBeroepDecided,
  // some other notifications are shown only when a more complex rule is true, e.g. a certain milestone is present, but another one is not
  // see function notifications()
};

const loaderLabelForMilestones = {
  [milestoneDefinitionKeys.REQUEST_INITIATED]:
    "We halen enkele gegevens voor u op bij een externe dienst. Dit kan even duren.",
  [milestoneDefinitionKeys.FETCH_DATA_COMPANY]:
    "We halen enkele gegevens voor u op bij een externe dienst. Dit kan even duren.",
  [milestoneDefinitionKeys.FETCH_DATA_CITIZEN]:
    "We halen enkele gegevens voor u op bij een externe dienst. Dit kan even duren.",
  [milestoneDefinitionKeys.REQUEST_INITIATED_IN_BO]:
    "We halen enkele gegevens voor u op bij een externe dienst. Dit kan even duren.",
  [milestoneDefinitionKeys.REQUEST_SUBMITTED]: "We dienen uw aanvraag in.",
};

const premiumAmountFieldForCategory = new Map([
  ["binnenrenovatie", "premieBinnenrenovatie"],
  ["muurrenovatie", "premieMuur"],
  ["dakrenovatie", "premieDak"],
  ["technischeInstallaties", "premieBElektriciteitEnSanitair"],
  ["gascondensatieketel", "premieGascondensatieketel"],
  ["buitenschrijnwerkGlas", "premieRamenEnDeuren"],
  ["vloerrenovatie", "premieVloer"],
  ["warmtepomp", "premieWarmtepomp"],
  ["warmtepompboiler", "premieWarmtepompboiler"],
  ["zonneboiler", "premieZonneboiler"],
]);

const mostRecentPremiumAmountFieldForCategory = new Map([
  ["binnenrenovatie", "recenstePremieBinnenrenovatie"],
  ["muurrenovatie", "recenstePremieMuur"],
  ["dakrenovatie", "recenstePremieDak"],
  ["technischeInstallaties", "recenstePremieElektriciteitEnSanitair"],
  ["gascondensatieketel", "recenstePremieGascondensatieketel"],
  ["buitenschrijnwerkGlas", "recenstePremieRamenEnDeuren"],
  ["vloerrenovatie", "recenstePremieVloer"],
  ["warmtepomp", "recenstePremieWarmtepomp"],
  ["warmtepompboiler", "recenstePremieWarmtepompboiler"],
  ["zonneboiler", "recenstePremieZonneboiler"],
]);

export function wrpDossierPageWrapper() {
  function WrpDossierPage(props) {
    const [loaderLabel, setLoaderLabel] = useState(
      loaderLabelForMilestones[props.getLatestMilestoneKey()]
    );
    const [fetchCurrentMilestoneInterval, setFetchCurrentMilestoneInterval] =
      useState(false);
    const [
      loadingDossierDetailsAfterMilestonesInterval,
      setLoadingDossierDetailsAfterMilestonesInterval,
    ] = useState(false);

    const dossierId = props.dossier.id;
    const fetchDossierDetails = props.fetchDossierDetails;
    const fetchCurrentDossierMilestone = props.fetchCurrentDossierMilestone;

    /* The current milestone is fetched every so seconds until no loaderLabel is defined for the current milestone */
    useEffect(() => {
      function cleanup() {
        if (fetchCurrentMilestoneInterval) {
          setFetchCurrentMilestoneInterval(false);
          clearInterval(fetchCurrentMilestoneInterval);

          // just to be sure, we have the latest dossier, we do one fetch of the dossier details
          setLoadingDossierDetailsAfterMilestonesInterval(true);
          fetchDossierDetails(dossierId).then(() =>
            setLoadingDossierDetailsAfterMilestonesInterval(false)
          );
        }
      }

      // getLatestMilestoneKey is based on the dossier details; here we're fetching the currentMilestone separately, so we need to watch that one
      const currentMilestone = props.currentMilestone?.key;
      const newLoaderLabel = loaderLabelForMilestones[currentMilestone];
      setLoaderLabel(loaderLabelForMilestones[currentMilestone]);

      if (newLoaderLabel && !fetchCurrentMilestoneInterval) {
        setFetchCurrentMilestoneInterval(
          setInterval(() => {
            fetchCurrentDossierMilestone(dossierId);
          }, 2000)
        );
      }
      if (!newLoaderLabel && fetchCurrentMilestoneInterval) {
        cleanup();
      }
      return () => {
        cleanup();
      };
    }, [
      fetchCurrentMilestoneInterval,
      loaderLabel,
      dossierId,
      fetchDossierDetails,
      fetchCurrentDossierMilestone,
      props.currentMilestone,
    ]);

    const { fetchWrpProfile } = props;

    useEffect(() => {
      fetchWrpProfile();
    }, [fetchWrpProfile]);

    function notifications() {
      // if these fields do not exist yet (because the documents are not created/the fields are not filled in), they will be undefined
      // if everything is setup correctly, the related milestone will not be active yet and the undefined fields will not be necessary
      const beslissingsdatum = moment(
        props.getFieldFromDocument(documentDefinitionKeys.WRP_REQUEST, [
          "aanvraagdatum",
        ])
      ).add(8, "M");

      const beroepbeslissingsdatum = moment(
        props.getMilestone(milestoneDefinitionKeys.BEROEP_SUBMITTED)?.timestamp
      ).add(3, "M");

      const aanvraagdatum = moment(
        props.getFieldFromDocument(documentDefinitionKeys.WRP_REQUEST, [
          "aanvraagdatum",
        ])
      );

      const additionalAttachmentsRequested = moment(
        props.getFieldFromDocument(documentDefinitionKeys.WRP_WACHTTIJD_BS, [
          "wachttijd",
        ])
      );

      const additionalAttachmentsDelivered = moment(
        props.getMilestone(
          milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_DELIVERED
        )?.timestamp
      );

      const additionalAttachmentsDeliveredBeroep = moment(
        props.getMilestone(
          milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_DELIVERED_BEROEP
        )?.timestamp
      );

      const deadlineForModificationRequestor = moment(
        props.getMilestone(
          milestoneDefinitionKeys.CHANGE_OF_REQUESTOR_REQUESTED
        )?.timestamp
      ).add(1, "M");

      const bedrag = undefined; // TODO
      const uitbetalingsdatum = undefined; // TODO
      const rekeningnummer = props.getFieldFromDocument(
        documentDefinitionKeys.WRP_REQUEST,
        ["accountnumber"]
      );

      var notificationForLatestMilestone =
        notificationsForMilestones[props.getLatestMilestoneKey()];

      if (
        !notificationForLatestMilestone &&
        props.getLatestMilestoneKey() !==
          milestoneDefinitionKeys.REQUEST_SUBMITTED
      ) {
        if (
          props.getMilestone(
            milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_REQUESTED
          )
        ) {
          if (
            props.getMilestone(
              milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_DELIVERED
            )
          ) {
            notificationForLatestMilestone =
              notitificationAdditionalAttachmentsDelivered;
          }
        } else if (
          props.getMilestone(
            milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_REQUESTED_BEROEP
          ) &&
          props.getMilestone(
            milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_DELIVERED_BEROEP
          )
        ) {
          notificationForLatestMilestone =
            notitificationAdditionalAttachmentsDeliveredBeroep;
        } else if (
          props.getMilestone(
            milestoneDefinitionKeys.DECISION_LETTER_TO_CLIENT_BEROEP
          ) ||
          props.getMilestone(milestoneDefinitionKeys.BEROEP_NOT_VALID)
        ) {
          notificationForLatestMilestone = notitificationBeroepDecided;
        } else if (
          props.getMilestone(milestoneDefinitionKeys.BEROEP_SUBMITTED)
        ) {
          notificationForLatestMilestone = notificationBeroepRequestSubmitted;
        } else if (
          props.getMilestone(milestoneDefinitionKeys.DECISION_LETTER_TO_CLIENT)
        ) {
          notificationForLatestMilestone = notitificationDossierDecided;
        } else {
          notificationForLatestMilestone = notificationRequestSubmitted;
        }
      }

      return notificationForLatestMilestone &&
        isFunction(notificationForLatestMilestone)
        ? [
            notificationForLatestMilestone({
              additionalAttachmentsRequested,
              additionalAttachmentsDelivered,
              additionalAttachmentsDeliveredBeroep,
              aanvraagdatum,
              deadlineForModificationRequestor,
              beslissingsdatum,
              bedrag,
              uitbetalingsdatum,
              rekeningnummer,
              beroepbeslissingsdatum,
            }),
          ]
        : [];
    }

    function dossierInformation() {
      return [
        ...(props.getMilestone(milestoneDefinitionKeys.AUTHENTIC_DATA_STARTED)
          ? []
          : [
              {
                label: "Aangemaakt op",
                value: moment(get(props.dossier, ["createdAt"])).format(
                  "D MMMM YYYY"
                ),
              },
            ]),
        {
          label: "Status",
          value: status(),
        },
        {
          label: "Ingediend op",
          value: submitDate(),
        },
        ...(props.getMilestone(milestoneDefinitionKeys.AUTHENTIC_DATA_STARTED)
          ? [
              {
                label: "EAN en adres",
                value: getEanNumberFromDocument(),
              },
            ]
          : []),
        ...(props.getMilestone(milestoneDefinitionKeys.AUTHENTIC_DATA_STARTED)
          ? [
              {
                label: "Communicatie",
                value: communication(),
              },
            ]
          : []),
        ...(props.getMilestone(milestoneDefinitionKeys.AUTHENTIC_DATA_STARTED)
          ? [
              {
                label: "Rekeningnummer",
                value: accountNumber(),
              },
            ]
          : []),
        ...(props.getFieldFromDocument(
          documentDefinitionKeys.WRP_GEGEVENS_KLANT_ANDERE_AANVRAGER,
          ["aanvraagPartij", "selectedOption"]
        ) === "energiehuis" &&
        props.getMilestone(milestoneDefinitionKeys.FETCH_DATA_CITIZEN)
          ? [
              {
                label: "Nummer Mijn VerbouwLening",
                value: energyLoan(),
              },
            ]
          : []),
        ...(props.getFieldFromDocument(documentDefinitionKeys.WRP_LOGIN_DATA, [
          "type",
        ]) !== "CITIZEN"
          ? [
              {
                label: "Aanvrager",
                value: applicant(),
              },
            ]
          : []),
        ...(props.is1700Employee &&
        props.getMilestone(milestoneDefinitionKeys.REQUEST_SUBMITTED)
          ? [
              {
                label: "Toegewezen aan",
                value: toegewezenAan(),
              },
            ]
          : []),
        // This is added to add some whitespace before the decision-table
        ...(!props.is1700Employee ? [{}] : []),
        ...[{}],
        ...[{}],
        ...(props.getMilestone(
          milestoneDefinitionKeys.DECISION_LETTER_TO_CLIENT
        )
          ? [{ label: "Beslissing per categorie", value: categoryTable() }]
          : props.getMilestone(milestoneDefinitionKeys.REQUEST_SUBMITTED)
          ? [
              {
                label: "Categorieën van werken",
                value: categoryList(),
              },
            ]
          : []),
      ];
    }

    function status() {
      if (
        props.getLatestMilestoneKey() ===
        milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_REQUESTED
      ) {
        return (
          <div className="vl-pill vl-pill--error">
            <span className="vl-pill__text">Dossier onvolledig</span>
          </div>
        );
      } else if (
        props.getLatestMilestoneKey() ===
        milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_REQUESTED_BEROEP
      ) {
        return (
          <div className="vl-pill vl-pill--error">
            <span className="vl-pill__text">Beroep: Dossier onvolledig</span>
          </div>
        );
      } else if (
        props.getMilestone(milestoneDefinitionKeys.UITBETALING_VOLTOOID_BEROEP)
      ) {
        return (
          <div className="vl-pill vl-pill--success">
            <span className="vl-pill__text">Beroep uitbetaald</span>
          </div>
        );
      } else if (
        props.getMilestone(
          milestoneDefinitionKeys.DECISION_LETTER_TO_CLIENT_BEROEP
        ) ||
        props.getMilestone(milestoneDefinitionKeys.BEROEP_NOT_VALID)
      ) {
        return (
          <div className="vl-pill vl-pill--success">
            <span className="vl-pill__text">Beroep beslist</span>
          </div>
        );
      } else if (props.getMilestone(milestoneDefinitionKeys.BEROEP_STARTED)) {
        return (
          <div className="vl-pill vl-pill--warning">
            <span className="vl-pill__text">Beroep in behandeling</span>
          </div>
        );
      } else if (
        props.getMilestone(milestoneDefinitionKeys.UITBETALING_VOLTOOID)
      ) {
        return (
          <div className="vl-pill vl-pill--success">
            <span className="vl-pill__text">Dossier uitbetaald</span>
          </div>
        );
      } else if (props.getMilestone(milestoneDefinitionKeys.BEROEP_SUBMITTED)) {
        return (
          <div className="vl-pill vl-pill--success">
            <span className="vl-pill__text">Beroep ingediend</span>
          </div>
        );
      } else if (
        props.getMilestone(milestoneDefinitionKeys.DECISION_LETTER_TO_CLIENT)
      ) {
        return (
          <div className="vl-pill vl-pill--success">
            <span className="vl-pill__text">Beslist</span>
          </div>
        );
      } else if (
        props.getMilestone(milestoneDefinitionKeys.REQUEST_SUBMITTED)
      ) {
        return (
          <div className="vl-pill vl-pill--warning">
            <span className="vl-pill__text">In behandeling</span>
          </div>
        );
      } else {
        return (
          <div className="vl-pill">
            <span className="vl-pill__text">Concept</span>
          </div>
        );
      }
    }

    function submitDate() {
      if (props.getMilestone(milestoneDefinitionKeys.REQUEST_SUBMITTED)) {
        return moment(
          props.getFieldFromDocument(documentDefinitionKeys.WRP_REQUEST, [
            "aanvraagdatum",
          ])
        ).format("D MMMM YYYY");
      } else {
        return "Nog niet ingediend";
      }
    }

    function getEanNumberFromDocument() {
      const eanNumber = props.getFieldFromDocument(
        documentDefinitionKeys.WRP_REQUEST,
        ["eanAndAddress", "eanCodeWoning"]
      );
      const eanFormatted =
        "54144" +
        " " +
        eanNumber.substring(0, 4) +
        " " +
        eanNumber.substring(4, 8) +
        " " +
        eanNumber.substring(8, 12) +
        " " +
        eanNumber.substring(12, 16) +
        " " +
        eanNumber.substring(17);
      const adress = props.getFieldFromDocument(
        documentDefinitionKeys.WRP_REQUEST,
        ["uitvoeringsadres"]
      );
      return (
        <div>
          <p key="adress1" style={{ alignItems: "center" }}>
            <span style={{ fontWeight: "normal" }}>EAN-nummer: </span>
            <span>{eanFormatted} </span>
          </p>
          <p key="adress2" style={{ alignItems: "center" }}>
            <span style={{ fontWeight: "normal" }}>Uitvoeringsadres: </span>
            <span>{adress.street} </span>
            <span>{adress.housenumber}</span>
          </p>
          <p key="adress3" style={{ alignItems: "center" }}>
            <span style={{ visibility: "hidden" }}>Uitvoeringsadres: </span>
            <span>{adress.zipcode} </span>
            <span>{adress.municipality}</span>
          </p>
        </div>
      );
    }

    function communication() {
      const option = props.getFieldFromDocument(
        documentDefinitionKeys.CONTACT_DATA,
        ["manierVanOpDeHoogteBrengen", "selectedOption"]
      );
      var optionLabel = props.getFieldFromDocument(
        documentDefinitionKeys.CONTACT_DATA,
        ["manierVanOpDeHoogteBrengen", "selectedOptionLabel", "nl"]
      );
      if (option === "andereOpties") {
        optionLabel = props.getFieldFromDocument(
          documentDefinitionKeys.CONTACT_DATA,
          [
            "manierVanOpDeHoogteBrengen",
            "subfields",
            "andereOptiesChoice",
            "selectedOptionLabel",
            "nl",
          ]
        );
      }

      const email = props.getFieldFromDocument(
        documentDefinitionKeys.CONTACT_DATA,
        ["emailAdres"]
      );

      const telefoonnummer = props.getFieldFromDocument(
        documentDefinitionKeys.CONTACT_DATA,
        ["telefoonnummer"]
      );
      const telephoneNumber =
        "+" +
        telefoonnummer.substring(0, 2) +
        " " +
        telefoonnummer.substring(2, 5) +
        " " +
        telefoonnummer.substring(5, 7) +
        " " +
        telefoonnummer.substring(7, 9) +
        " " +
        telefoonnummer.substring(9, 11);

      const postOption =
        props.getFieldFromDocument(documentDefinitionKeys.CONTACT_DATA, [
          "manierVanOpDeHoogteBrengen",
          "subfields",
          "andereOptiesChoice",
          "selectedOption",
        ]) === "post";
      const deliveryAdress = props.getFieldFromDocument(
        documentDefinitionKeys.CONTACT_DATA,
        [
          "manierVanOpDeHoogteBrengen",
          "subfields",
          "andereOptiesChoice",
          "subfields",
          "contactAdres",
        ]
      );
      return (
        <div>
          <p key="communicatieVoorkeur" style={{ alignItems: "center" }}>
            <span style={{ fontWeight: "normal" }}>
              Communicatie voorkeur:{" "}
            </span>
            <span>{optionLabel}</span>
          </p>
          <p key="email" style={{ alignItems: "center" }}>
            <span style={{ fontWeight: "normal" }}>Email: </span>
            <span>{email}</span>
          </p>
          <p key="telephoneNumber" style={{ alignItems: "center" }}>
            <span style={{ fontWeight: "normal" }}>Telefoonnummer: </span>
            <span>{telephoneNumber}</span>
          </p>
          {postOption ? (
            <>
              <p key="deliveryAdress1" style={{ alignItems: "center" }}>
                <span style={{ fontWeight: "normal" }}>Afleveradres: </span>
                <span>{deliveryAdress.street} </span>
                <span>{deliveryAdress.housenumber}</span>
              </p>
              <p key="deliveryAdress2" style={{ alignItems: "center" }}>
                <span style={{ visibility: "hidden" }}>Afleveradres: </span>
                <span>{deliveryAdress.zipcode} </span>
                <span>{deliveryAdress.municipality}</span>
              </p>
            </>
          ) : null}
        </div>
      );
    }

    function accountNumber() {
      const belgischRekeningNummer = props.getFieldFromDocument(
        documentDefinitionKeys.CONTACT_DATA,
        ["accountnumber"]
      );
      const geenBelgischRekeningNummer = props.getFieldFromDocument(
        documentDefinitionKeys.CONTACT_DATA,
        ["geenBelgischRekeningnummer"]
      );
      return geenBelgischRekeningNummer
        ? props.getFieldFromDocument(documentDefinitionKeys.CONTACT_DATA, [
            "buitenlandsRekeningnummer",
          ])
        : "BE" +
            belgischRekeningNummer.substring(0, 2) +
            " " +
            belgischRekeningNummer.substring(2, 6) +
            " " +
            belgischRekeningNummer.substring(6, 10) +
            " " +
            belgischRekeningNummer.substring(10, 14);
    }

    function energyLoan() {
      const energyLoan = props.getFieldFromDocument(
        documentDefinitionKeys.WRP_GEGEVENS_KLANT_ANDERE_AANVRAGER,
        ["nummerVanDeEnergielening"]
      );
      return energyLoan.substring(0, 10) + "-" + energyLoan.substring(10, 12);
    }

    function applicant() {
      //if the applicant is a company but is no mandator show name company
      if (
        !props.getFieldFromDocument(documentDefinitionKeys.WRP_LOGIN_DATA, [
          "RRNorKBONumber",
        ]) &&
        props.getFieldFromDocument(documentDefinitionKeys.WRP_LOGIN_DATA, [
          "naamOrganisatie",
        ])
      ) {
        return props.getFieldFromDocument(
          documentDefinitionKeys.WRP_LOGIN_DATA,
          ["naamOrganisatie"]
        );
      }
      return (
        props.getFieldFromDocument(documentDefinitionKeys.WRP_LOGIN_DATA, [
          "voornaam",
        ]) +
        " " +
        props.getFieldFromDocument(documentDefinitionKeys.WRP_LOGIN_DATA, [
          "naam",
        ])
      );
    }

    function toegewezenAan() {
      return props.getFieldFromDocument(
        documentDefinitionKeys.WRP_DOSSIERINFORMATION,
        ["toegewezenAan", "selectedOptionLabel", "nl"]
      );
    }

    function categoryList() {
      const categories = props.getFieldFromDocument(
        documentDefinitionKeys.WRP_REQUEST,
        ["selectieCategorieen", "geselecteerdeCatgeorieen", "selectedOptions"]
      );
      const categoryLabels = map(
        props.getFieldFromDocument(documentDefinitionKeys.WRP_REQUEST, [
          "selectieCategorieen",
          "geselecteerdeCatgeorieen",
          "selectedOptionsLabels",
        ]),
        (labels) => labels.nl
      );
      return (
        <div>
          {map(categories, (categoryKey, index) => (
            <p
              key={categoryKey}
              className="vl-icon-wrapper"
              style={{ alignItems: "center" }}
            >
              <span
                className={classnames(
                  "vl-icon vl-icon--before",
                  categoryIcons[categoryKey]
                )}
                aria-hidden="true"
              ></span>
              <span>{categoryLabels[index]}</span>
            </p>
          ))}
        </div>
      );
    }

    function categoryTable() {
      const categories = props.getFieldFromDocument(
        documentDefinitionKeys.WRP_REQUEST,
        ["selectieCategorieen", "geselecteerdeCatgeorieen", "selectedOptions"]
      );
      const categoryLabels = map(
        props.getFieldFromDocument(documentDefinitionKeys.WRP_REQUEST, [
          "selectieCategorieen",
          "geselecteerdeCatgeorieen",
          "selectedOptionsLabels",
        ]),
        (labels) => labels.nl
      );

      //Most recent amount is the amount of the first decision. Current is the amount that is calculated right now.
      function premiumAmount(categoryKey, isCurrentAmount) {
        let amount = 0;
        if (isCurrentAmount) {
          amount = props.getFieldFromDocument(
            documentDefinitionKeys.WRP_FINANCIAL_CONTROL,
            ["computations", premiumAmountFieldForCategory.get(categoryKey)]
          );
        } else {
          amount = props.getFieldFromDocument(
            documentDefinitionKeys.WRP_DOSSIERINFORMATION,
            [mostRecentPremiumAmountFieldForCategory.get(categoryKey)]
          );
        }
        if (!amount) amount = 0;
        return "€ " + amount;
      }

      function isAppealInProgress() {
        return (
          (props.getMilestone(
            milestoneDefinitionKeys.BEROEP_ATTEST_CONTROL_NEEDED
          ) ||
            props.getMilestone(
              milestoneDefinitionKeys.BEROEP_ATTEST_CONTROL_NOT_NEEDED
            ) ||
            props.getMilestone(
              milestoneDefinitionKeys.BEROEP_BACK_TO_TECHNICAL_CONTROL
            ) ||
            props.getMilestone(
              milestoneDefinitionKeys.BEROEP_BACK_TO_FINANCIAL_CONTROL
            )) &&
          !(
            props.getMilestone(
              milestoneDefinitionKeys.DOSSIER_DEF_APPROVED_BEROEP
            ) ||
            props.getMilestone(
              milestoneDefinitionKeys.DOSSIER_DEF_REJECTED_BEROEP
            )
          )
        );
      }

      function decisionPill(categoryKey) {
        const decisionForCategories = props.getFieldFromDocument(
          documentDefinitionKeys.WRP_DOSSIERINFORMATION,
          ["goedgekeurdeCategorieen", "selectedOptions"]
        );

        const decisionForCategoriesFirstLineTreatment =
          props.getFieldFromDocument(
            documentDefinitionKeys.WRP_DOSSIERINFORMATION,
            ["recentstGoedgekeurdeCategorieen", "selectedOptions"]
          );

        if (
          (isAppealInProgress() &&
            decisionForCategoriesFirstLineTreatment &&
            decisionForCategoriesFirstLineTreatment.includes(categoryKey) &&
            props.getMilestone(milestoneDefinitionKeys.DOSSIER_DEF_APPROVED)) ||
          (decisionForCategories &&
            decisionForCategories.includes(categoryKey) &&
            (props.getMilestone(milestoneDefinitionKeys.DOSSIER_DEF_APPROVED) ||
              props.getMilestone(
                milestoneDefinitionKeys.DOSSIER_DEF_APPROVED_BEROEP
              )))
        ) {
          return (
            <div className="vl-pill vl-pill--success">
              <span className="vl-pill__text">Goedgekeurd</span>
            </div>
          );
        } else {
          return (
            <div className="vl-pill vl-pill--error">
              <span className="vl-pill__text">Geweigerd</span>
            </div>
          );
        }
      }

      return (
        <table className="vl-data-table vl-data-table--">
          <thead>
            <tr>
              <th>Categorie</th>
              <th>Beslissing</th>
              <th>Premiebedrag</th>
            </tr>
          </thead>
          <tbody>
            {map(categories, (categoryKey, index) => (
              <tr key={categoryKey}>
                <td data-title="Categorie">
                  <p
                    className="vl-icon-wrapper"
                    style={{ alignItems: "center" }}
                  >
                    <span
                      className={classnames(
                        "vl-icon vl-icon--before",
                        categoryIcons[categoryKey]
                      )}
                      aria-hidden="true"
                    ></span>
                    <span>{categoryLabels[index]}</span>
                  </p>
                </td>
                <td data-title="Beslissing">{decisionPill(categoryKey)}</td>
                <td data-title="Premiebedrag">
                  {props.getMilestone(
                    milestoneDefinitionKeys.DOSSIER_DEF_APPROVED
                  ) ||
                  props.getMilestone(
                    milestoneDefinitionKeys.DOSSIER_DEF_APPROVED_BEROEP
                  )
                    ? isAppealInProgress()
                      ? premiumAmount(categoryKey, false)
                      : premiumAmount(categoryKey, true)
                    : "€ 0"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }

    function downloads() {
      const downloads = [];
      if (props.getCommunication("beslissingsbriefBeroep")) {
        if (
          props.getMilestone(
            milestoneDefinitionKeys.DECISION_LETTER_BEROEP_ONGEGROND_TO_CLIENT
          )
        ) {
          downloads.push(
            props.mapCommunicationToPdfDownloadInfo(
              props.getCommunication("beslissingsbriefBeroep"),
              "Beslissingsbrief beroep"
            )
          );
        }
      }
      if (props.getCommunication("ontvangstbevestigingBeroep")) {
        downloads.push(
          props.mapCommunicationToPdfDownloadInfo(
            props.getCommunication("ontvangstbevestigingBeroep"),
            "Ontvangstbevestiging beroep"
          )
        );
      }
      if (
        (props.getMilestone(
          milestoneDefinitionKeys.DECISION_LETTER_TO_CLIENT
        ) &&
          !props.getMilestone(milestoneDefinitionKeys.BEROEP_STARTED)) ||
        props.getMilestone(
          milestoneDefinitionKeys.DECISION_LETTER_TO_CLIENT_BEROEP
        )
      ) {
        downloads.push(
          props.mapCommunicationToPdfDownloadInfo(
            props.getCommunication("beslissingsbrief"),
            "Beslissingsbrief"
          )
        );
        if (
          props.getMilestone(
            milestoneDefinitionKeys.DETAIL_DECISION_LETTER_SENT
          )
        ) {
          downloads.push(
            props.mapCommunicationToPdfDownloadInfo(
              props.getCommunication("detailBeslissing"),
              "Detail beslissing"
            )
          );
        }
      }
      if (props.getCommunication("ontvangstbevestigingBeroepTegenStilzitten")) {
        downloads.push(
          props.mapCommunicationToPdfDownloadInfo(
            props.getCommunication("ontvangstbevestigingBeroepTegenStilzitten"),
            "Ontvangstbevestiging beroep tegen stilzitten"
          )
        );
      }
      if (
        props.getMilestone(
          milestoneDefinitionKeys.COMMUNICATION_TO_REQUESTOR_INITIATED
        )
      ) {
        downloads.push(
          props.mapCommunicationToPdfDownloadInfo(
            props.getCommunication("losseCommunicatie"),
            "Communicatie over uw dossier"
          )
        );
      }
      if (
        props.getMilestone(
          milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_REQUESTED
        )
      ) {
        downloads.push(
          props.mapCommunicationToPdfDownloadInfo(
            props.getCommunication("bijkomendeStukken"),
            "Bijkomende stukken aan te leveren"
          )
        );
      }
      if (
        props.getMilestone(
          milestoneDefinitionKeys.CHANGE_OF_REQUESTOR_REQUESTED
        )
      ) {
        downloads.push(
          props.mapCommunicationToPdfDownloadInfo(
            props.getCommunication("wijzigingAanvrager"),
            "Voorstel wijzigen aanvrager"
          )
        );
      }
      if (props.getCommunication("ontvangstbevestiging")) {
        downloads.push(
          props.mapCommunicationToPdfDownloadInfo(
            props.getCommunication("ontvangstbevestiging"),
            "Ontvangstbevestiging"
          )
        );
      }
      if (props.getMilestone(milestoneDefinitionKeys.REQUEST_SUBMITTED)) {
        downloads.push(
          props.mapDocumentToPdfDownloadInfo(
            props.getDocument(documentDefinitionKeys.WRP_REQUEST),
            "Aanvraag",
            "aanvraagOverzicht"
          )
        );
      }
      return downloads;
    }

    function getActiveTasks() {
      if (props.loadingDossierDetails) return;
      const allTasks = get(props, ["dossierDetails", "task"], []);
      return allTasks.filter(function (task) {
        return task.active;
      });
    }

    function executeTask(taskId) {
      router.goToState(pageNames.TASK, { id: taskId });
    }

    const propsToPass = {
      ...props,
      loaderLabel,
      loadingDossierDetails:
        props.loadingDossierDetails ||
        loadingDossierDetailsAfterMilestonesInterval,
      contactInformation,
      notifications: notifications(),
      dossierInformation: dossierInformation(),
      downloads: downloads(),
      getMyActiveTasksInDossier: getActiveTasks,
      executeTask: executeTask,
    };

    return (
      <>
        <CoreDossierPage {...propsToPass} />
      </>
    );
  }

  WrpDossierPage.propTypes = { ...externalProps, ...internalProps };

  return WrpDossierPage;
}

const mapStateToProps = (state) => ({
  is1700Employee: is1700Employee(state),
});

export default coreDossierPageWrapper(
  connect(mapStateToProps, { fetchWrpProfile })(wrpDossierPageWrapper())
);
