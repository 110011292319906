import React from "react";
import WRPSectionWizard from "../WRPSectionWizard";

export default function MVPSectionWizard(props) {
  const selectFieldsetName = "selectieCategorieen";

  const onlyWhenExpressionsForCategories = {
    binnenrenovatie: "binnenrenoIsAangevraagd",
    muurrenovatie: "muurrenoMuurIsAangevraagd",
    dakrenovatie: "dakrenovatieIsAangevraagd",
    technischeInstallaties: "technischeInstallatiesIsAangevraagd",
    gascondensatieketel: "gascondensatieketelIsAangevraagd",
    buitenschrijnwerkGlas: "buitenschrijnwerkGlasIsAangevraagd",
    vloerrenovatie: "vloerrenoFunderingVloerisolatieIsAangevraagd",
    warmtepomp: "warmtepompIsAangevraagd",
    warmtepompboiler: "warmtepompboilerIsAangevraagd",
    zonneboiler: "zonneboilerIsAangevraagd",
  };

  function buildSectionStructure(
    buildDefaultSection,
    buildSelectCategoriesSection
  ) {
    return [
      buildDefaultSection(0),
      buildDefaultSection(1),
      buildSelectCategoriesSection(2),
      buildDefaultSection(3),
      buildDefaultSection(4),
      buildDefaultSection(5),
    ];
  }

  const title = "Aanvraag Mijn VerbouwPremie";

  const welcomeText = (
    <>
      <p>
        Via onderstaande stappen kan u Mijn VerbouwPremie, de eengemaakte premie
        voor renovatie en energiebesparende maatregelen, aanvragen.
      </p>
      <p>
        <b>Controleer</b> op voorhand of u voldoet aan alle {""}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          target="_blank"
          href="https://www.vlaanderen.be/bouwen-wonen-en-energie/bouwen-en-verbouwen/premies-en-belastingvoordelen/mijn-verbouwpremie"
          className="vl-link"
          rel="noreferrer"
        >
          voorwaarden
          <span className="vl-u-visually-hidden">(opent in nieuw venster)</span>
          <span
            className="vl-link__icon vl-link__icon--after vl-link__icon--light vl-vi vl-vi-external"
            aria-hidden="true"
          ></span>
        </a>
        (opent in nieuw venster) om in aanmerking te komen.
      </p>
      <p>
        U kunt de premie pas aanvragen <b>na voltooiing en facturatie</b> van de
        werken. Het is mogelijk om de premie in meerdere aanvragen op te
        splitsen en per categorie van werken een aanvraag in te dienen.
      </p>
      <p>
        U moet alle documenten <b>digitaal bijvoegen</b>. Zorg dat u deze ter
        beschikking hebt alvorens u start met een aanvraag.
      </p>
      <p>Overzicht documenten:</p>
      <ul>
        <li>facturen</li>
        <li>
          <a
            href="https://www.vlaanderen.be/bouwen-wonen-en-energie/bouwen-en-verbouwen/premies-en-belastingvoordelen/mijn-verbouwpremie/attesten-voor-de-aannemer"
            target="_blank"
            rel="noreferrer"
          >
            attesten
          </a>{" "}
          (de aannemer die de werken uitvoert, vult het attest in)
        </li>
        <li>bewijsstukken</li>
      </ul>
      <p>
        Lees meer over {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          target="_blank"
          href="https://www.vlaanderen.be/bouwen-wonen-en-energie/bouwen-en-verbouwen/premies-en-belastingvoordelen/mijn-verbouwpremie"
          className="vl-link"
          rel="noreferrer"
        >
          MijnVerbouwPremie
          <span className="vl-u-visually-hidden">(opent in nieuw venster)</span>
          <span
            className="vl-link__icon vl-link__icon--after vl-link__icon--light vl-vi vl-vi-external"
            aria-hidden="true"
          ></span>
        </a>
        en ons {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          target="_blank"
          href="https://www.vlaanderen.be/bouwen-wonen-en-energie/bouwen-en-verbouwen/premies-en-belastingvoordelen/mijn-verbouwpremie/privacyverklaring-mijn-verbouwpremie"
          className="vl-link"
          rel="noreferrer"
        >
          Privacybeleid
          <span className="vl-u-visually-hidden">(opent in nieuw venster)</span>
          <span
            className="vl-link__icon vl-link__icon--after vl-link__icon--light vl-vi vl-vi-external"
            aria-hidden="true"
          ></span>
        </a>
      </p>
    </>
  );

  return (
    <WRPSectionWizard
      onlyWhenExpressionsForCategories={onlyWhenExpressionsForCategories}
      selectFieldsetName={selectFieldsetName}
      buildSectionStructure={buildSectionStructure}
      title={title}
      welcomeText={welcomeText}
      {...props}
    />
  );
}
